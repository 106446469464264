'use client'
import dynamic from 'next/dynamic';
const Sidebar = dynamic(() => import('./Sidebar'),{ ssr: false })
const Header = dynamic(() => import('./Header'),{ ssr: false })
const Footer = dynamic(() => import('./Footer'),{ ssr: false })
import { Poppins} from 'next/font/google'
 
const poppins = Poppins({
  preload:true,
  weight: '400',
  subsets: ['latin', 'latin-ext'],
  display: 'swap',
})

const HomeLayout = ({ children }) => {
    const hideMobilemenu = () => {
        document.getElementById("sidebarArea").classList.remove("showSidebar");
      };
    return (
        <main className={poppins.className}>
            {/********header**********/}
            <Header />
            {/********Sidebar**********/}
            <aside className="sidebarArea shadow-lg d-lg-none" id="sidebarArea" style={{ zIndex: "4" }}>
                <Sidebar />
            </aside>
            <div onClick={() => hideMobilemenu()}>
                {children}
            </div>
            {/********Footer**********/}
            <Footer />
        </main>
    );
};

export default HomeLayout;
