import Session from "supertokens-auth-react/recipe/session";
import ThirdPartyPasswordless from 'supertokens-web-js/recipe/thirdpartypasswordless'

import config from './localconfig';
const urlBase = config.BASE_URL;

export const SuperTokensConfig = {

    appInfo: {
        appName: "Lifemeasure",
        apiDomain: urlBase,
        websiteDomain: urlBase,
    },

    // recipeList contains all the modules that you want to
    // use from SuperTokens. See the full list here: https://supertokens.com/docs/guides
    recipeList: [
        ThirdPartyPasswordless.init(),
        Session.init(),
    ],
};

